<template>
    <div  class="bg">

    </div>
</template>
<script>
export default {
    data(){
        return{
            showHead:false,
        };
    },
    mounted(){

    }
}
</script>
<style scoped>

</style>