<template>
    <div>
        <myHead/>
        <div>
            <a-result>
                <template #title>
                    <span style="letter-spacing:2px">哎呀，出错啦~</span>
                </template>
                <template #subTitle>
                    <span style="letter-spacing:1px">麻烦您在客服群反馈一下此问题，我们会马上解决！</span>
                </template>

                <template #icon>
                    <FrownOutlined />
                </template>
                <!-- <template #extra>
                        <a-tag color="#f50">对不起</a-tag>

                    <a-button type="primary">吐槽</a-button>
                </template> -->
            </a-result>
        </div>
        <myFooter/>
    </div>
</template>
<script>
 
import myHead from '../components/myHead'
import myFooter from '../components/myFooter'
import { FrownOutlined} from '@ant-design/icons-vue';

import {   } from '@/request.js'

export default {
    
    components:{myHead,myFooter,FrownOutlined},
    data() {
        return {

        };
    },
    methods: {

    },
    created(){
    },
    mounted() {

    }
};
</script>
<style scoped>

</style>